import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
      <SEO title="404 Error" keywords={[`scryer`, `software`, `developers`, `development`, `jobs` , `career`]} />
    <h1>~ 404 Error ~</h1>
    <p>~ Do not pass go, do not collect 200 ~</p>
  </Layout>
)

export default NotFoundPage
